import React, { FC, useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import IconCustom from 'gatsby-theme-gaviscon/src/components/common/IconCustom';
import { graphql, useStaticQuery } from 'gatsby';
import { setStorageData, getStorageData } from 'gatsby-theme-gaviscon/src/utils/storage';
import { LANGUAGE_SELECTOR_LOCALSTORAGE_KEY, DEFAULT_LANGUAGE } from './const/constants';
import classNames from 'classnames';
import useScreenRecognition from 'gatsby-theme-gaviscon/src/hooks/useScreenRecognition';

import LanguageSelectorItem from './LanguageSelectorItem';
import { LanguageSelectorProps } from './models';
import './LanguageSelector.scss';

const LanguageSelector: FC<LanguageSelectorProps> = ({ items, isModal }) => {
  const {
    commonSettings: { blocks }
  } = useStaticQuery(graphql`
    {
      commonSettings {
        blocks {
          properties {
            productLngSelector {
              properties {
                title
                description
                shortDescription
                productLngs {
                  properties {
                    title
                    code
                    prefix
                    ariaLabel
                  }
                }
              }
            }
          }
          structure
        }
      } 
    }
  `);

  const [isHidden, setHidden] = useState<boolean>(true);
  const [defaultLanguage, setDefaultLanguage] = useState<string>('');
  const { properties: { productLngSelector }} = blocks.find(elem => elem.structure === 'Product');
  if(!productLngSelector.length) return null;

  const { title, description, shortDescription, productLngs } = productLngSelector[0]?.properties;
  const classes = classNames('language-selector', {
    'language-selector__hide': !isModal,
  });
  const { isDesktop } = useScreenRecognition();
  // display component always if it's not modal version
  const isLanguageSelectorDisplayed = ((!isHidden && isDesktop) || !isModal);
 
  useEffect(() => {
    const storageData = getStorageData(LANGUAGE_SELECTOR_LOCALSTORAGE_KEY);
    setDefaultLanguage(storageData);
    setHidden(Boolean(storageData));
  }, []);

  const handleDefaultLanguage = (link: string) => {
    setStorageData(LANGUAGE_SELECTOR_LOCALSTORAGE_KEY, link);
  }

  return isLanguageSelectorDisplayed && productLngs?.length ?
    <div className={classes}>
      {isModal ?
        <>
          <button className="language-selector__close" onClick={() => setHidden(true)}>
            <IconCustom icon="close" />
          </button>
          <h2>{title}</h2>
          <p className="language-selector__description">{description}</p>
        </> : null
      }
      <Dropdown 
        className="language-selector__dropdown"
      >
        <Dropdown.Toggle as="span">
          <p className="language-selector__toggle">
            {isModal ? <span> {DEFAULT_LANGUAGE} </span> : 
            <span> {`${shortDescription} ${defaultLanguage}`} </span>}
            <IconCustom icon="chevron-down" />
          </p>
          <span className="language-selector__dropdown--arrow" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {items.map(item =>
            <LanguageSelectorItem
              key={item}
              setDefaultLanguage={handleDefaultLanguage}
              productLanguagesData={productLngs}
              link={item}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
  </div> : null
};

export default LanguageSelector;

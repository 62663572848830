import React, { FC } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'gatsby';
import { LanguageSelectorItemProps } from './models';
import { DEFAULT_LANGUAGE } from '../const/constants';

const LanguageSelectorItem: FC<LanguageSelectorItemProps> = ({ link, productLanguagesData, setDefaultLanguage }) => {
  const productLangCode = link.match(/\/(.*?)\//g);
  const languageSelectorData = productLanguagesData.find(({ properties: { prefix } }) => {
    return productLangCode?.length ? productLangCode[1] === prefix : undefined;
  });

  return <Dropdown.Item as="div">
    <Link
      className="language-selector__link"
      onClick={() => setDefaultLanguage(languageSelectorData?.properties?.title || DEFAULT_LANGUAGE  )}
      to={link}
    >
      {languageSelectorData ? languageSelectorData.properties.title : DEFAULT_LANGUAGE }
    </Link>
  </Dropdown.Item>
};

export default LanguageSelectorItem;
